<template>
  <v-sheet
    class="mx-auto my-5"
    max-width="95%"
  >
    <h3 class="text-center" >{{ $t("priscription.処方箋・指示箋一覧") }}</h3>
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate/>
      <br/>
      {{$t("priscription.お客様の情報を確認中です。<br/>処理が完了するまでお待ちください。")}}
    </div>
    <v-card class="ma-2" v-if="clinic && loading === false">
      <p class="text-center" v-if="patient">{{$t("患者様番号")}}
        <br/>
        {{ this.patient.patientNumber }}
      </p>
      <v-card-title class="justify-center">
        {{clinic.name}}
      </v-card-title>
      <v-img
        class="ma-5"
        height="50"
        :src="clinic.logourl"
        aspect-ratio="1.7"
        contain
      ></v-img>
        <v-card-text>
        {{clinic.comment}}
        </v-card-text>
      <v-card-text>
        <div>
          <p>
            {{$t("welcome.販売元")}}：<b>{{clinic.saler_name}}</b>
            <ul style="list-style:none">
              <li>{{$t("welcome.特定商取引法に基づく表記")}}: {{clinic.sales_license}}</li>
              <li><a :href=clinic.sct_url target="_brank">{{$t("welcome.特定商取引法に基づく表記")}}</a></li>
              <li>{{$t("welcome.住所")}}: {{clinic.saler_address}}</li>
              <li>{{$t("welcome.ホームページ")}}: <a :href=clinic.saler_weburl target="_brank">{{clinic.saler_weburl}}</a></li>
              <li>{{$t("welcome.ご注文品の配送状況や破損品交換のお問い合わせ")}}</li>
              <li>{{$t("welcome.営業日")}}: {{clinic.saler_date}}</li>
              <li>{{$t("welcome.営業時間")}}: {{clinic.saler_time}}</li>
              <li>{{$t("welcome.電話番号")}}: {{clinic.saler_call}}</li>
              <li>{{$t("welcome.E-mail")}}: {{clinic.saler_email}}</li>
            </ul>
          </p>
        </div>
       </v-card-text>

    </v-card>

    <v-card  v-if="prescriptions.length == 0">
      <v-card-text>
          <b>有効な処方・指示箋はございません。</b>
          <br/>
          クリニックで発行されました処方・指示箋が反映されましたら通知のショートメッセージをお送りします。
          <br/>
          通知のショートメッセージが届くまでお待ちください。
      </v-card-text>
    </v-card>
    <v-list-item color="blue lighten-4" v-for="(prescription, index) in this.prescriptions" v-bind:key="prescription.id">
        <v-list-item-content>
          <v-card>
          <v-card-text>
          <v-simple-table >
            <tbody>
              <tr>
                <td>
                  <v-icon
                    class="blue"
                    dark
                  >mdi-clipboard-text</v-icon>
                </td>
                <td>
                  <b>{{$t("発行日")}}</b>
                  <b v-if="prescription !== null && prescription.created_at !== undefined">
                    {{new Date(prescription.created_at.seconds * 1000) | moment }}
                  </b>
                </td>
              </tr>

              <tr v-if="prescription !== null && prescription.r_productName !== ''">
                  <td colspan="2"><v-icon color="orange">mdi-eye</v-icon> {{$t("右目")}}
                  </td>
               </tr>
              <tr v-if="prescription !== null && prescription.r_productName !== '' && prescription.r_productName !== '指示箋なし'">
                  <td colspan="2" calign="left" v-if="prescription !== null ">
                    {{prescription.r_productName}}
                  </td>
              </tr>
              <tr v-if="prescription !== null && prescription.r_productName !== '' && prescription.r_productName !== '指示箋なし'">
                  <td align="right" >{{$t("有効期限")}}
                  </td>
                  <td align="left" v-if="prescription !== null && prescription.created_at !== undefined">{{product_expiration_date(prescription.created_at, prescription.r_product_expiration_date)}}
                  </td>
                  <td align="left" v-if="prescription !== null && prescription.created_at !== undefined">{{product_expiration_date(prescription.created_at, prescription.product_expiration_date)}}
                  </td>
              </tr>
              <tr v-if="prescription !== null && prescription.r_productName !== '' && prescription.r_productName !== '指示箋なし'">
                  <td align="right" >{{$t("処方可能数")}}
                  </td>
                  <td align="left" v-if="prescription !== null && prescription.r_product_limit !== undefined">
                  {{prescription.l_product_expiration_date}}
                  </td>
                  <td align="left" v-if="prescription !== null && prescription.product_limit !== undefined">
                  {{prescription.product_limit}}
                  </td>
              </tr>
              <tr v-if="prescription !== null && prescription.l_productName !== ''">
                  <td colspan="2"><v-icon color="orange">mdi-eye</v-icon>{{$t("左目")}}
                  </td>
              </tr>
              <tr v-if="prescription !== null && prescription.l_productName !== ''">
                  <td colspan="2" calign="left" v-if="prescription !== null">
                    {{prescription.l_productName}}
                  </td>
              </tr>
              <tr v-if="prescription !== null && prescription.l_productName !== '' && prescription.l_productName !== '指示箋なし'">
                  <td align="right" > {{$t("有効期限")}}
                  </td>
                  <td align="left" v-if="prescription !== null && prescription.created_at !== undefined">{{product_expiration_date(prescription.created_at, prescription.l_product_expiration_date)}}
                  </td>
                  <td align="left" v-if="prescription !== null && prescription.created_at !== undefined">{{product_expiration_date(prescription.created_at, prescription.product_expiration_date)}}
                  </td>
              </tr>
              <tr v-if="prescription !== null && prescription.r_productName !== '' && prescription.r_productName !== '指示箋なし'">
                  <td align="right" >{{$t("処方可能数")}}
                  </td>
                  <td align="left" v-if="prescription !== null && prescription.l_product_limit !== undefined">
                  {{prescription.l_product_expiration_date}}
                  </td>
                  <td align="left" v-if="prescription !== null && prescription.product_limit !== undefined">
                  {{prescription.product_limit}}
                  </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div v-if="true">
              <v-btn outlined color="green" elevation="2" large class="ml-5" @click="select_priscription(index)">
                  {{$t("購入へ進む")}}
              </v-btn>
          </div>
          </v-card-text>
        </v-card>
        </v-list-item-content>
    </v-list-item>
  </v-sheet>
</template>


<script>

import moment from "moment"

import { Firebase } from "../config/firebase"

export default {
  name: "about",
  data: () => ({
    user: null,
    page: 1,
    patient: null,
    loading: true,
    showModal: false,
    checkout: false,
    error: false,
    patient_id: "",
    manufactures: [],
    prescriptions: [],
    go_carts: [],
    clinic: undefined
  }),
  components: {
  },
  computed: {
    clinic_id() {
      return this.$route.params.id
    },

    product_expiration_date(){
      return function(create_date, expiration_date_string) {
        if(undefined === expiration_date_string || create_date == undefined) {
          return ""
        }
        if (expiration_date_string === "制限なし"){
          return expiration_date_string
        }else{
          let limit = 0
          if (expiration_date_string.includes("ヶ月")) {
            let result = expiration_date_string.replace("ヶ月", "");
            limit = Number(result);
          } else if (expiration_date_string.includes("年間")) {
            let result = expiration_date_string.replace("年間", "");
            limit = Number(result) * 12;
          } else if (expiration_date_string.includes("日間")) {
            let result = expiration_date_string.replace("日間", "");
            limit = Math.floor(Number(result) / 30);
          } else if (expiration_date_string.includes("週間")) {
            let result = expiration_date_string.replace("週間", "");
            limit = (Number(result) * 7) / 30;
          }

          let myDate = new Date(create_date.toMillis());
          let create_at = moment(myDate);
          let exfire_date = create_at.clone();
          if (limit < 1) {
            exfire_date.add(limit * 30, "days");
          } else {
            exfire_date.add(limit, "months");
          }
          return exfire_date.format("YYYY/MM/DD");
        }
      };
    }
  },
  created: async function () {
    this.loading = true
    await this.fetchUsers()
    await this.fetchClinic()
    this.loading = false
  },
  methods: {
    check_manufacture: async function(name){
      let is_able =false
      const id = this.clinic_id + "_" + name
      await Firebase
        .firestore()
        .collection("Manufactures")
        .doc(id).get().then((doc) => {
          if (doc.exists) {
            is_able = true
          } else {
            is_able = false
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
          is_able = false
      });
      return is_able
    },

    select_priscription: async function (index) {
        this.$router.push({ name: "prescription" , params: {id : this.prescriptions[index].id}, query: { patient_id : this.patient_id, patientNumber: this.patient.patientNumber}})
    },
    goHome() {
      this.$router.push({ name: "clinic_list"})
    },
    fetchClinic: async function () {
      try {
         const clinicRef = await Firebase.firestore()
        .collection("Clinics")
        .doc(this.clinic_id);
      await clinicRef.get().then((doc) => {
        if (doc.exists) {
          this.clinic = doc.data();
          this.clinic.id = doc.id;
        } else {
          console.log("No clinic document!");
        }
        this.error = true;
      });
      } catch(e) {
        if (this.error == true) {
          this.$router.push({ name: "error", params: { message:  "表示できません。" } })
        } else {
          console.error("fetchClinic ",e)
        }
      } finally {
        //this.loading = false
      }
    },
    fetchUsers: async function(){
      const userRef = await Firebase.firestore()
      .collection("Users")
      .doc(this.$store.getters.user.uid);
      await userRef.get().then((doc) => {
        if (doc.exists) {
          this.user = doc.data();
          this.user.id = doc.id;
          this.clinics = this.user.clinics;
        } else {
          console.log("No user document!");
        }
        this.error = true;
      });
      this.fetchPatient()
    },
    fetchPatient: async function () {
      let clinics = this.user.clinics
      if (clinics.length > 0) {
        for (const clinic of clinics) {
          if (clinic.clinic_id === this.clinic_id) {
            this.patient_id = clinic.patient_id
            break
          }
        }
      }
      if( this.patient_id != null){
        const patientRef = Firebase
          .firestore()
          .collection('Patients').doc(this.patient_id);
        const doc = await patientRef.get();
        if (!doc.exists) {
          console.log('No such Patients document!');
        } else {
          this.patient = doc.data()
          this.patient.id = doc.id
        }
        await this.fetchPrescription(this.patient.patientNumber)
      }else{
        this.$router.push({ name: "error", params: { message:  "表示できません。" } })
      }
    },

    fetchPrescription : async function (patientNumber) {
      this.prescriptions = []
      this.items = []
      await Firebase
        .firestore()
        .collection("Prescriptions")
        .where("clinic_id", "==", this.clinic_id)
        .where("patient_patientNumber", "==", patientNumber)
        .where("publish_state", "==", "allowed")
        .get().then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              let temp_item = doc.data()
              temp_item.id = doc.id

              let expiration_date = moment(this.product_expiration_date(temp_item.created_at, temp_item.r_product_expiration_date),"YYYY/MM/DD")
              let today = moment()
              if(expiration_date.isAfter(today) ){
                this.prescriptions.push(temp_item)
              }

            })
          }
      }).catch((error) => {
          console.log("Error getting prescriptios document:", error);
      });
      for(var i=0; i < this.prescriptions.length; i++){
        const l_able = await this.check_manufacture(this.prescriptions[i].l_productName)
        const r_able = await this.check_manufacture(this.prescriptions[i].r_productName)
        if(l_able == true && r_able == true){
          this.prescriptions[i].is_able = true
          this.$set(this.prescriptions[i], 'is_able', true);
        }else{
          this.$set(this.prescriptions[i], 'is_able', false);
        }
      }
    }
  },
  filters: {
      moment: function (date) {
        return moment(date).format('YYYY/MM/DD')
      },
      price: function (value) {
        if (!value) return ''
        value = value.toString()
        return value+"円";
      }
  },
};
</script>
